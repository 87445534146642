import {useEffect} from "react";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import instance from "../api";
import {useAuthStore} from "../stores/auth";
import {useCustomNotification} from "./useCustomNotification";
import {refreshToken} from "../api/member";

export function useAxiosInterceptor() {
    const authState = useAuthStore();
    const {failNotify} = useCustomNotification();

    const onRequest = (config: InternalAxiosRequestConfig) => {
        if (authState.isLoggedIn()) {
            config.headers.Authorization = `Bearer ${authState.accessToken}`;
        }

        return config;
    }

    const onResponse = (response: AxiosResponse) => {
        return response.data;
    };

    const onErrorResponse = async (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
            const {message} = error;
            const {method, url} = error.config as AxiosRequestConfig;
            const {data, statusText, status} = error.response as AxiosResponse;

            // access token 만료
            if (status === 401 && data.errorCode === 'E902') {
                const {success, data} = await refreshToken();
                if (success) {
                    authState.accessToken = data.accessToken;
                    authState.updateAccessToken(data.accessToken);
                    return instance(error.config as AxiosRequestConfig);
                }

                authState.reset();
                failNotify('사용자 토큰이 만료되었어요. 다시 로그인해주세요.');
                window.location.href = '/members/sign-in';
                return;
            }

            console.log(`🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`);
        } else {
            console.log(`🚨 [API] | Error ${error}`);
        }

        return Promise.reject(error);
    };

    const requestInterceptor = instance.interceptors.request.use(onRequest);
    const responseInterceptor = instance.interceptors.response.use(onResponse, onErrorResponse);

    useEffect(() => {
        return () => {
            instance.interceptors.request.eject(requestInterceptor);
            instance.interceptors.response.eject(responseInterceptor);
        };
    }, [requestInterceptor, responseInterceptor]);
}
