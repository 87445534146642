import {notifications} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import {rem} from "@mantine/core";
import React from "react";

export const useCustomNotification = () => {

    const successNotify = (message: string) => {
        notifications.show({
            color: 'teal',
            message: message,
            icon: <IconCheck style={{width: rem(18), height: rem(18)}}/>,
            autoClose: 2000,
        });
    }

    const failNotify = (message: string) => {
        notifications.show({
            color: 'red',
            message: message,
            icon: <IconX style={{width: rem(18), height: rem(18)}}/>,
            autoClose: 2000,
        });
    }

    return {successNotify, failNotify};
};
