import React from "react";
import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./pages/common/ErrorPage";
import RootPage from "./pages/common/RootPage";
import {ROOT_URL, SIGN_IN_URL, SIGN_UP_URL} from "./constants/url";

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootPage/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: ROOT_URL,
                async lazy() {
                    const HomePage = await import("./pages/HomePage");
                    return {Component: HomePage.default};
                },
            },
            //==회원 관련 페이지==//
            {
                path: SIGN_UP_URL,
                async lazy() {
                    const SignUpPage = await import("./pages/member/SignUpPage");
                    return {Component: SignUpPage.default};
                },
            },
            {
                path: SIGN_IN_URL,
                async lazy() {
                    const SignInPage = await import("./pages/member/SignInPage");
                    return {Component: SignInPage.default};
                },
            },
            //==회원 관련 페이지==//
            //==주가 관련 페이지==//
            {
                path: "/stocks",
                async lazy() {
                    const StockListPage = await import("./pages/stock/StockListPage");
                    return {Component: StockListPage.default};
                },
            },
            {
                path: "/stocks/:stockCode",
                async lazy() {
                    const StockDetailPage = await import("./pages/stock/StockDetailPage");
                    return {Component: StockDetailPage.default};
                },
            },
            //==주가 관련 페이지==//
            //==종목검증 관련 페이지==//
            {
                path: "/portfolios",
                async lazy() {
                    const PortfolioListPage = await import("./pages/portfolio/PortfolioListPage");
                    return {Component: PortfolioListPage.default};
                },
            },
            {
                path: "/portfolios/:portfolioId",
                async lazy() {
                    const PortfolioDetailPage = await import("./pages/portfolio/PortfolioDetailPage");
                    return {Component: PortfolioDetailPage.default};
                },
            },
            //==종목검증 관련 페이지==//
            //==리포트 관련 페이지==//
            {
                path: "/reports",
                async lazy() {
                    const ReportListPage = await import("./pages/report/ReportListPage");
                    return {Component: ReportListPage.default};
                },
            },
            //==리포트 관련 페이지==//
        ]
    },
]);

export default router;
