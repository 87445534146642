import {createTheme, MantineProvider} from '@mantine/core';
import React from 'react';
import router from "./AppRouter";
import {RouterProvider} from "react-router-dom";
import {QueryClient, QueryClientProvider, QueryErrorResetBoundary} from "@tanstack/react-query";
import {DatesProvider} from "@mantine/dates";
import 'dayjs/locale/ko';
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

const theme = createTheme({
    fontFamily: 'Pretendard, sans-serif',
    primaryColor: 'dark',
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    },
})

function App() {
    return (
        <QueryErrorResetBoundary>
            <QueryClientProvider client={queryClient}>
                <MantineProvider theme={theme} defaultColorScheme="light">
                    <ModalsProvider>
                        <Notifications position="top-center" zIndex={1000}/>
                        <DatesProvider settings={{locale: 'ko', firstDayOfWeek: 0, weekendDays: [0]}}>
                            <RouterProvider router={router}/>
                        </DatesProvider>
                    </ModalsProvider>
                </MantineProvider>
            </QueryClientProvider>
        </QueryErrorResetBoundary>
    );
}

export default App;
